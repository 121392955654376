import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import React from "react";
import NavBar from "./navBar";
import Footer from "./footer";
import { createCode } from "./apiCodes";
import { toastMessage } from './toastMessage';

const CheckFirstStep = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { price, name, id, message } = location.state || {};
  const [email, setEmail] = useState('');

  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(price || 0);

  useEffect(() => {
    if (!name || !price) {
      navigate(`/`);
    }
  }, [name, price, id, navigate]);

  useEffect(() => {
    if (price) {
      setTotal(quantity * price);
    }
  }, [quantity, price]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleButtonClick = async () => {
    if (!validateEmail(email)) {
      toastMessage("Por favor ingrese un email válido.");
      return;
    }

    try {
      const code = await createCode(email);
      if (code.message === 'SENT') {
        navigate(`/evento/${id}/checkSecondStep`, {
          state: {
            price: price,
            id: id,
            name: name,
            email: email,
            quantity: quantity
          }
        });
      }
      if (code.message === 'ALREADY_SENT') {
        navigate(`/evento/${id}/checkSecondStep`, {
          state: {
            price: price,
            id: id,
            name: name,
            email: email,
            quantity: quantity,
            message: 'El código ya ha sido enviado previamente. Chequea tu bandeja de entrada'
          }
        });
      }
    } catch (error) {
      console.error('Error creating code:', error);
    }
  };

  useEffect(() => {
    if (message) {
      toastMessage(message);
    }
  }, [message]);

  return (
    <>
      <NavBar />
      
      <div className="name-display">{name || 'No name available'}</div>
      <div className="price-display">
      <div className="quantity-container">
      <label htmlFor="quantity">Cantidad:</label>
      <div className="quantity-controls">
        <button
          type="button"
          className="quantity-btn"
          onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
        >
          &ndash; {/* Signo de menos */}
        </button>
        <button
          type="button"
          className="quantity-btn"
          onClick={() => setQuantity(quantity + 1)}
        >
          &#43; {/* Signo de más */}
        </button>
      </div>
      </div>
        {price ? `${quantity} x $${price} = $${total}` : 'No price available'}
      </div>
      <div className="email-container">
        <h5 className="email-message">Vamos a enviar un código a tu email para verificar tu identidad</h5>
        <input
          type="email"
          id="email"
          name="email"
          className="email-input"
          placeholder="Ingrese el email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="submit-button" onClick={handleButtonClick}>
          Continuar
        </button>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default CheckFirstStep;
