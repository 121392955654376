import React from "react";
import DetalleImagen from "./detalleImagen";
import DetalleDeImagen from "./detalleDeProducto";
import NavBar from "./navBar";
import Footer from "./footer";

const ProductoDetalle = () => {
  
  return (
    <>
      <NavBar />
      <DetalleImagen/>
      <DetalleDeImagen/>
      <Footer/>
    </>
  );
};

export default ProductoDetalle;
