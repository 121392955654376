import React from "react";
import NavBar from "./navBar";
import Footer from "./footer";
import { Link } from 'react-router-dom';


const IntegrationFail = () => {
  return (
    <>
      <NavBar />
      <div className="email-container">
      <h5 className="error-message">Integracion fallo</h5>
        <Link to={`/`}>
          <button className="submit-button">Volver</button>
        </Link>
      </div>
      <Footer/>
    </>
  );
};

export default IntegrationFail;