import { Principal } from './principal'
import { Secundario } from './secundario';
import Terciarios from './terciarios';
import NavBar from './navBar';
import Footer from './footer';

function PaginaPrincipal (){
  return (
    <>
      <NavBar />
      <Principal />
      <Secundario />
      <Terciarios />
      <Footer />
      </>
  );
}

export default PaginaPrincipal;
