import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastMessage = (message) => {
    toast.info(message, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide,
        icon: false, 
        style: {
          backgroundColor: 'rgba(242, 98, 80, 0.9)', 
          color: 'white', 
          fontSize: '14px',
          fontWeight: 'bold',
          padding: '8px',
          borderRadius: '10px',
          textAlign: 'center',
        },
      });
}