import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { getEvents } from "./apiEventos";

const Terciarios = () => {
  const [productos, setProductos] = useState([]);
  const URL_HOST_PUBLIC = process.env.REACT_APP_API_HOST_PUBLIC;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const events  = await getEvents();
        const productosTerciarios = events.filter(e => e.category === "GENERAL");
        if (productosTerciarios) {
          setProductos(productosTerciarios);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="terciario">
      <div className="card-group ter">
        {productos.map(producto => (
          <div className="card m-3 card-ter" key={producto.id}>
            <img src={URL_HOST_PUBLIC +'/'+ producto.imageThumbnail} className="card-img-top img-ter" alt="..." />
            <Link to={`/evento/${producto.id}`} className="btn btn-info btn-ter">
              Más Información
            </Link>
            <h1 className="titulo_terciario">{producto.name}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Terciarios;