const URL = process.env.REACT_APP_API_HOST;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;

export const getEvents = async () => {
    try {
        const response = await fetch(`${URL}/events`, {
            method: "GET",
            headers: {
                "Authorization": `Basic ${btoa(`${username}:${password}`)}`
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throwing the error so it can be caught by the caller
    }
};

export const getEventById = async (id) => {
    try {
        const response = await fetch(`${URL}/events/${id}`, {
            method: "GET",
            headers: {
                "Authorization": `Basic ${btoa(`${username}:${password}`)}`
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throwing the error so it can be caught by the caller
    }
};

export const checkoutEvent = async (id, mail, quantity) => {
    try {
        const response = await fetch(`${URL}/events/${id}/checkout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${btoa(`${username}:${password}`)}`
            },
            body: JSON.stringify({
                mail: mail,
                quantity: quantity
            })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.text(); 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

