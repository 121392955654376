import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import NavBar from "./navBar";
import Footer from "./footer";
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { checkCode } from "./apiCodes";
import { checkoutEvent } from "./apiEventos";
import { toastMessage } from './toastMessage';

const CheckSecondStep = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { price, name, id, email, quantity } = location.state || {};

  const [code, setCode] = useState(Array(6).fill(""));

  useEffect(() => {
    if (!name || !price || !email)  {
      navigate(`/`);
    }
  }, [name, price, id, navigate]);

  const handleCodeChange = (value, index) => {
    const upperValue = value.toUpperCase();
    const newCode = [...code];
    newCode[index] = upperValue;
    setCode(newCode);

    if (upperValue && index < code.length - 1) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }
  };

  useEffect(() => {
    if (location.state?.message) {
      toastMessage(location.state.message);
    }
  }, [location.state?.message]);

  const handleSubmit = async () => {
    const verificationCode = code.join("");

    try {
      const response = await checkCode(email, verificationCode);
      if (response === "EQUAL") {
        const redirectUrl = await checkoutEvent(id, email, quantity);
        window.location.href = redirectUrl;
      } else if (response === "NOT_EQUAL") {
        toastMessage("El código no coincide. Por favor intentar nuevamente");
        setCode(Array(6).fill(""));
      } else if (response === "ALREADY_VERIFIED") {
        toastMessage("El código ya ha sido verificado. Por favor intentar nuevamente");
        setCode(Array(6).fill(""));
      } else if (response === "EXPIRED") {
        navigate(`/evento/${id}/checkFirstStep`, {
          state: { price, id, name, message: 'El código ya expiró. Por favor, ingresar nuevamente el mail' }
        });
      }
    } catch (error) {
      console.error("Error during code verification:", error);
    }
  };

  return (
    <>
      <NavBar />
      <div className="name-display">{name || 'No name available'}</div>
      <div className="price-display">{price ? `Total: $${price*quantity}` : 'No price available'}</div>
      <div className="email-container">
        <div className="email-label">{email}</div>
        <label htmlFor="email" className="email-label">Ingresar tu código:</label>
        <div className="code-inputs">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <input
                key={index}
                id={`code-input-${index}`}
                type="text"
                maxLength="1"
                className="code-input"
                value={code[index]}
                onChange={(e) => handleCodeChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
        </div>
        <button className="submit-button" onClick={handleSubmit}>
          Continuar
        </button>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default CheckSecondStep;
