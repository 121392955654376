import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEventById } from "./apiEventos";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const DetalleImagen = () => {
  const { id } = useParams();
  const location = useLocation();
  const { price } = location.state || {};
  const { name } = location.state || {};
  const [producto, setProducto] = useState(null);

  const URL_HOST_PUBLIC = process.env.REACT_APP_API_HOST_PUBLIC;

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const productoEncontrado  = await getEventById(id);
        setProducto(productoEncontrado);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData(id);
  }, []);

  if (!producto) {
    return <div>Cargando...</div>;
  }
  return (
    <>
      <div className="container">
        <div className="card mb-3 card_detalle col-xs-10">
          {producto && (
            <>
              <img
                src={URL_HOST_PUBLIC +'/'+ producto.imageMain}
                className="card-img-top img-detalle"
                alt="..."
              />
            </>
          )}
          <div className="d-flex flex-row titulo_detalle_div">
            <div className="titulo-compartir col-sm-7">
              <h1 className="titulo_detalle">{producto.name}</h1>
            </div>
            <div class="col-sm-8">
                  <Link to={`/evento/${id}/checkFirstStep`} className="boton-comprar"
                        state={{ price:  `${price}`,
                        id:  `${id}`,
                        name:  `${name}`}}>
                  COMPRAR TICKETS
                  </Link>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleImagen;
