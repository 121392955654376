import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { getEventById } from "./apiEventos";

const DetalleDeImagen = () => {
    const { id } = useParams();
    const [producto, setProducto] = useState(null);

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);      
      const day = date.getDate();    

      const monthNames = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
      ];
      const monthName = monthNames[date.getMonth()];

      const year = date.getFullYear();
      const formattedDate = `${day} de ${monthName} del ${year}`;
      return formattedDate;
    };
  
    useEffect(() => {
      const fetchData = async (id) => {
        try {
          const productoEncontrado  = await getEventById(id);
          setProducto(productoEncontrado);
        } catch (error) {
          console.log(error);
        }
      };
    
      fetchData(id);
    }, []);

    if (!producto) {
        return <div>Cargando...</div>;
      }

  return (
    <>  
       <div className="container card-group-contacto">
    <Card className="card-principal-contacto" >
      <Card.Body className="card-contacto">      
        <Card.Title>Precio:</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Desde: ${producto.price}</Card.Subtitle>
        <Card.Title>Contacto</Card.Title>
        <Card.Text>
          <ul>
            <li>{producto.contact.name}</li>
            <li>{producto.contact.cellPhone}</li>
            <li>{producto.contact.mail}</li>
          </ul>
        </Card.Text>
        <Card.Title>Fecha del evento</Card.Title>
        <Card.Text>
         {formatDate(producto.eventDate)}           
        </Card.Text>
      </Card.Body>
    </Card>
  
    <Card  className="card-principal-descripcion" >
      <Card.Body className="card-contacto">      
        <Card.Title>{producto.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Desde: ${producto.price}</Card.Subtitle> 
        <Card.Text>
        {producto.description}
        </Card.Text>
      </Card.Body>
    </Card>

    <Card className="card-principal-direccion" >
      <Card.Body className="card-contacto">      
        <Card.Title>Direccion del Evento</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{producto.address.city}</Card.Subtitle>
        <Card.Text>
        {producto.address.street} {producto.address.number} 
        </Card.Text>
      </Card.Body>
    </Card>

    </div>
    </>
  );
};

export default DetalleDeImagen
