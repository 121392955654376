import './App.css';
import PaginaPrincipal from './components/paginaPrincipal';
import Router from './routes';

function App() {
  return (
    <Router>
     <PaginaPrincipal/>
    </Router>
  );
}

export default App;
