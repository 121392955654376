import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PaginaPrincipal from './components/paginaPrincipal';
import  ProductoDetalle  from './components/productoDetalle';
import CheckFirstStep from './components/checkFirstStep';
import CheckSecondStep from './components/checkSecondStep';
import CheckFailed from './components/checkFailed';
import CheckSuccesful from './components/checkSuccesful';
import IntegrationFail from './components/integrationFail';
import IntegrationSuccessFul from './components/integrationSuccess';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PaginaPrincipal />} />
        <Route path="/evento/:id" element={<ProductoDetalle />} />
        <Route path="/evento/:id/checkFirstStep" element={<CheckFirstStep />} />
        <Route path="/evento/:id/checkSecondStep" element={<CheckSecondStep />} />
        <Route path="/evento/:id/checkFailed" element={<CheckFailed />} />
        <Route path="/evento/:id/checkSuccesful" element={<CheckSuccesful />} />
        <Route path="/evento/:id/integrationSuccessful" element={<IntegrationSuccessFul />} />
        <Route path="/evento/:id/integrationFail" element={<IntegrationFail />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;