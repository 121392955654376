import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { getEvents } from "./apiEventos";

export const Principal = () => {
  const [productoMain, setProductoMain] = useState(null);

  const URL_HOST_PUBLIC = process.env.REACT_APP_API_HOST_PUBLIC;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const events = await getEvents();
        const mainProduct = events.find(e => e.category === "MAIN");
        if (mainProduct) {
          setProductoMain(mainProduct);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="principal">
      <div className="card mb-3 card_principal">
        {productoMain && (
          <>
            <img
              src={URL_HOST_PUBLIC +'/'+ productoMain.imageMain}
              className="card-img-top principal-img"
              alt="Imagen para computadora"
            />
            <img
              src={URL_HOST_PUBLIC +'/'+ productoMain.imageSecondary}
              className="card-img-top principal-img-movil"
              alt="Imagen para dispositivos móviles"
            />
            <Link to={`/evento/${productoMain.id}`} 
            className="btn btn-info btn-pri"
            state={{ price:  `${productoMain.price}`,
                     id:  `${productoMain.id}`,
                     name:  `${productoMain.name}`}}>
              Más Información
            </Link>
            <h1 className="titulo_principal">{productoMain.name}</h1>
          </>
        )}
      </div>
    </div>
  );
};
