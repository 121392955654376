import React from "react";
import NavBar from "./navBar";
import Footer from "./footer";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const IntegrationSuccessFul = () => {
  const location = useLocation();
  const { price, name, id } = location.state || {};
  return (
    <>
      <NavBar />
      <div className="email-container">
      <h5 className="successful-message">Integracion exitosa</h5>
        <Link to={`/`}>
          <button className="submit-button">Volver</button>
        </Link>
      </div>
      <Footer/>
    </>
  );
};

export default IntegrationSuccessFul;