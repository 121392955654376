import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { getEvents } from "./apiEventos";

export const Secundario = () => {
  const [productos, setProductos] = useState([]);
  const URL_HOST_PUBLIC = process.env.REACT_APP_API_HOST_PUBLIC;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const events  = await getEvents();
        const productosSecundarios = events.filter(e => e.category === "SECONDARY");
        if (productosSecundarios) {
          setProductos(productosSecundarios);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="secundario">
      <div className="card-group mb-3 sec">
        {productos.map(producto => (
          <div className="card m-3 card-sec" key={producto.id}>
            <img src={URL_HOST_PUBLIC +'/'+ producto.imageSecondary} className="card-img-top img-sec" alt="..." />
            <img src={URL_HOST_PUBLIC +'/'+ producto.imageThumbnail} className="card-img-top img-sec-movil" alt="..." />
            <Link to={`/evento/${producto.id}`} className="btn btn-info btn-sec"
                  state={{ price:  `${producto.price}`,
                           id:  `${producto.id}`,
                           name:  `${producto.name}`}}>
              Más Información
            </Link>
            <h1 className="titulo_secundario">{producto.name}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};