import React from "react"

function Footer() {
    return (
      <>
         <footer className="footer">
      <div className="container">
        <p>© {new Date().getFullYear()} Mi Sitio Web. Todos los derechos reservados.</p>
        <p>Contacto: Gonzalo Guernica</p>
        <p>Tel: 351-*******</p>
      </div>
    </footer>

</>)
}

export default Footer