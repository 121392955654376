
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';    
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <>
      <Navbar className='navBar'>
        <Container className='containerNav'>
          <Navbar.Brand className="elemNav" ></Navbar.Brand>
          <Nav className="ms-auto elemNava">
            <Link className='inicio' to="/">INICIO</Link>
          </Nav>
        </Container>
      </Navbar>
      </>
  );
}

export default NavBar;